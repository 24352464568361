import React from 'react';
import styledComponents from 'styled-components';
import THEME from '../../themes';

export const Divisor = styledComponents.div`
  width: 100%;
  height: 3px;
  background: transparent linear-gradient(180deg, ${THEME.COLORS.PRIMARY3} 0%, ${THEME.COLORS.PRIMARY} 49%, ${THEME.COLORS.SHADOW} 100%) 0% 0% no-repeat padding-box;
`;

const DashedLine = styledComponents.div`
  width: 0px;
  height: 25px;
  border: 1px dashed #D0AD67;
  opacity: 1;
  margin: 0 auto;
`;

const DivisorCircle = styledComponents.div`
  width: 20px;
  height: 20px;
  transform: matrix(0, 1, -1, 0, 0, 0);
  border: 2px solid #D0AD67;
  opacity: 1;
  margin: 5px auto;
  border-radius: 20px;
`;

const ContentDivisorContainer = styledComponents.div`
  width: 40px;
  margin: 0 auto;
  padding-top: 40px;
`;

export const ContentDivisor = () => (
  <ContentDivisorContainer>
    <DashedLine />
    <DivisorCircle />
  </ContentDivisorContainer>
);
