import { Link } from 'gatsby';
import styledComponents from 'styled-components';
import THEME from '../../themes';

export const PrimaryLinkButton = styledComponents(Link)`
  display: inline-flex;
  justify-content: space-evenly;
  align-items: center;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #81818180;
  border-radius: 20px;
  text-align: center;
  font: normal normal bold 22px/21px ${THEME.TEXT.FONT_NAME};
  letter-spacing: 0px;
  color: ${THEME.COLORS.SECONDARY};
  padding: 5px 30px;
  cursor: pointer;
  text-decoration: none;

  & > * {
    margin: 0 10px;
  }

`;

export const PrimaryLinkButtonWithoutGatsby = styledComponents.a`
  display: inline-flex;
  justify-content: space-evenly;
  align-items: center;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #81818180;
  border-radius: 20px;
  text-align: center;
  font: normal normal bold 22px/21px ${THEME.TEXT.FONT_NAME};
  letter-spacing: 0px;
  color: ${THEME.COLORS.SECONDARY};
  padding: 5px 30px;
  cursor: pointer;
  text-decoration: none;

  & > * {
    margin: 0 10px;
  }

`;

export const SecondaryLinkButton = styledComponents(Link)`
  display: inline-flex;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #81818180;
  border-radius: 20px;
  text-align: center;
  font: normal normal bold 22px/21px ${THEME.TEXT.FONT_NAME};
  letter-spacing: 0px;
  color: ${THEME.COLORS.PRIMARY};
  padding: 10px 30px;
  text-shadow: none;
  cursor: pointer;
  text-decoration: none;
`;
