/* eslint-disable react/prop-types */
import React from 'react';
import styledComponents from 'styled-components';
import GeneralDefinitions from '../../config/generalDefinitions';
import THEME from '../../themes';
import { animateExibition } from '../../utilities/windowManagement';
import { PrimaryLinkButtonWithoutGatsby } from '../button';
import { WhatsappIcon } from '../icons';

const { units } = GeneralDefinitions;

export const openWP = () => {
  animateExibition('#wpmodal');
};

export const closeWP = () => {
  animateExibition('#wpmodal');
};

const WPModalContainer = styledComponents.div`
  position: absolute;
  width: 80%;
  max-width: 360px;
  height: 0px;
  opacity: 0;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #81818180;
  opacity: 1;
  z-index: -1;
  top: 78px;
  margin-left: auto;
  right: 5px;
  padding: 60px 18px;
  display: none;
  transition: all .2s linear;
  overflow: hidden;

  @media screen and (max-width: 599px){
    top: 53px;
  }
`;

const UnitsContainer = styledComponents.div`
  text-align: left;
  font: ${THEME.TEXT.BODY.FONT};
  color: ${THEME.TEXT.BODY.COLOR};
`;

const UnitName = styledComponents.div`
  font: ${THEME.TEXT.LEAD_TOPIC.FONT};
  text-transform: ${THEME.TEXT.LEAD_TOPIC.TEXT_TRANSFORM};
`;
const UnitAddress = styledComponents.div``;

const Unit = styledComponents.div`
  margin-bottom: 20px;
  max-width: 80%;
  margin: 0 auto;
`;

const CloseButton = styledComponents.a`
  position: absolute;
  top: 65px;
  right: 15px;
  display: block;
  text-align: center;
  font: normal normal bold 22px/21px PT Sans;
  color: ${THEME.COLORS.PRIMARY};
  width: 30px;
  height: 30px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #81818180;
  border-radius: 15px;
  border: 1px solid ${THEME.COLORS.PRIMARY};
  opacity: 1;
  line-height: 30px;
  cursor: pointer;
  z-index: 5;

  @media screen and (max-width: 470px){
    display: none;
  }
`;

const createUnit = (unit, i) => (
  <>
    <Unit key={unit.name}>
      <UnitName>{unit.name}</UnitName>
      <UnitAddress>{unit.address}</UnitAddress>
      <br />
      <PrimaryLinkButtonWithoutGatsby
        href={`https://api.whatsapp.com/send?phone=${unit.whatsapp}`}
        target='_blank'
        rel="noreferrer"
        style={{
          fontSize: 20,
          padding: 0,
          width: '100%',
        }}
        >
        <WhatsappIcon color={ THEME.COLORS.SECONDARY } /> Marque aqui sua consulta
      </PrimaryLinkButtonWithoutGatsby>
    </Unit>
    { i === 0 ? <hr style={{ margin: '30px auto', maxWidth: '80%' }}/> : '' }
  </>
);

export const Units = () => (
  <UnitsContainer>
    {units.map(createUnit)}
  </UnitsContainer>
);

export const WhatsappModal = () => (
  <WPModalContainer id='wpmodal'>
    <CloseButton className='left' onClick={closeWP}>X</CloseButton>
    <Units />
  </WPModalContainer>
);
