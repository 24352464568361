import React from 'react';
import treatmentIcons from '../components/icons/treatmentIcons';

const GeneralDefinitions = {};

GeneralDefinitions.units = [
  {
    name: 'UNIDADE SÃO PAULO',
    address: 'Ed. Royal Office, Av. Brig. Luís Antônio, 3.421, sala 514, Jardins',
    towerAddress: 'Ed. Royal Office, Av. Brig. Luís Antônio, 3.421, Jardins',
    mapLink: 'https://www.google.com/maps/place//data=!4m2!3m1!1s0x94ce590abf6054b1:0x49fa6be891f17397?source=g.page.m.np._',
    mapEmbedded: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.0490883930615!2d-46.658488770921096!3d-23.575099601167217!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce590abf6054b1%3A0x49fa6be891f17397!2sCl%C3%ADnica%20Dermatol%C3%B3gica%20Angelucci%20S%C3%A3o%20Paulo!5e0!3m2!1spt-BR!2sbr!4v1670368261563!5m2!1spt-BR!2sbr',
    whatsapp: '5511995998883',
    phones: [
      {
        ddd: 11,
        number: '2364.1174',
      },
      {
        ddd: 11,
        number: '2361.4515',
      },
    ],
  },
  {
    name: 'UNIDADE SÃO BERNARDO DO CAMPO',
    address: 'Ed. Pasteur, R. Jurubatuba, 845, sala 23, Centro',
    towerAddress: 'Ed. Pasteur, R. Jurubatuba, 845, Centro',
    mapLink: 'https://maps.app.goo.gl/zN8aaT2fEv4GCG2LA?g_st=im',
    mapEmbedded: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3653.1864193096835!2d-46.5530046!3d-23.705036!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce4375bef034d1%3A0x2e8b72807a416885!2sCl%C3%ADnica%20Dermatol%C3%B3gica%20Angelucci%20S%C3%A3o%20Bernardo%20do%20Campo!5e0!3m2!1spt-BR!2sbr!4v1671677452739!5m2!1spt-BR!2sbr',
    whatsapp: '5511914158373',
    phones: [
      {
        ddd: 11,
        number: '4330.2459',
      },
      {
        ddd: 11,
        number: '2988.1622',
      },
      {
        ddd: 11,
        number: '2598.6389',
      },
    ],
  },
];

GeneralDefinitions.clinicalDiseases = [
  { name: 'Abscessos e Infecções Cutâneas', icon: treatmentIcons.iconAbscessosECeratoseSeborreica },
  { name: 'Acne', icon: treatmentIcons.iconAcne },
  { name: 'Alergias', icon: treatmentIcons.iconAlergiasDermatiteAtopicaDisidrose },
  { name: 'Alopécia (Queda de Cabelo)', icon: treatmentIcons.iconAlopeciaMesoterapiaCapilar },
  { name: 'Câncer de Pele Melanoma e Não-Melanoma', icon: treatmentIcons.iconCancerDePele },
  { name: 'Cicatrizes Hipertróficas e Quelóides', icon: treatmentIcons.iconCicatrizesHipertroficasECelulite },
  { name: 'Celulite e Estrias', icon: treatmentIcons.iconCicatrizesHipertroficasECelulite },
  { name: 'Ceratose Actínica', icon: treatmentIcons.iconCeratoseAcniticaVerrugas },
  { name: 'Ceratose Seborréica', icon: treatmentIcons.iconAbscessosECeratoseSeborreica },
  { name: 'Dermatite Atópica', icon: treatmentIcons.iconAlergiasDermatiteAtopicaDisidrose },
  { name: 'Dermatite de Contato', icon: treatmentIcons.iconDermatiteContato },
  { name: 'Dermatite Seborreica (Caspa)', icon: treatmentIcons.iconDermatiteSeborreica },
  { name: 'Disidrose', icon: treatmentIcons.iconAlergiasDermatiteAtopicaDisidrose },
  { name: 'Herpes', icon: treatmentIcons.iconHerpes },
  { name: 'Lúpus', icon: treatmentIcons.iconLupusMoluscoPsoriaseUrticarias },
  { name: 'Melanoses (Manchas)', icon: treatmentIcons.iconMelanoses },
  {
    name: 'Micoses Cutâneas e de Anexos',
    icon: treatmentIcons.iconDesbastamentoCalosidade,
    content: (<>
    <p className='lead'>Doença</p>
    <p>
      A Onicomicose (micose das unhas) é uma doença muito prevalente na população.
      Além de ser um incômodo estético, a doença pode ser uma porta de entrada e
      evoluir para complicações como a erisipela. A avaliação do dermatologista
      é muito importante para o sucesso do tratamento!
    </p>
    <div className='line' />
    <p className='lead'>Tratamento</p>
    <p>
      Medicamentos por via oral, tópicos e laser são as melhores escolhas para
      eliminar os fungos nas unhas. São necessárias várias sessões de laser
      para eliminar a doença completamente!
    </p>
  </>),
  },
  { name: 'Molusco Contagioso', icon: treatmentIcons.iconLupusMoluscoPsoriaseUrticarias },
  { name: 'Onicocriptose (Unha Encravada)', icon: treatmentIcons.iconMicosesOnicocriptoseMatricectomia },
  { name: 'Psoríase', icon: treatmentIcons.iconLupusMoluscoPsoriaseUrticarias },
  { name: 'Queimaduras', icon: treatmentIcons.iconQueimadurasCistosLipomas },
  { name: 'Rosácea', icon: treatmentIcons.iconRosacea },
  { name: 'Urticárias', icon: treatmentIcons.iconLupusMoluscoPsoriaseUrticarias },
  { name: 'Verrugas', icon: treatmentIcons.iconCeratoseAcniticaVerrugas },
  { name: 'Vitiligo', icon: treatmentIcons.iconVitiligo },
];

GeneralDefinitions.cirurgicalDiseases = [
  {
    name: 'Biópsia',
    icon: treatmentIcons.iconBiopsiaEnxertosERetalhosExcisaoCirurgica,
    content: (<>
      <p className='lead'>Procedimento</p>
      <p>
        Consiste na retirada de um fragmento cutâneo ou de mucosa, realizada
        sob anestesia local, com o intuito de auxiliar no diagnóstico da doença
        a ser investigada. Após a coleta da amostra, a peça é enviada para análise
        anatomopatológica detalhada, feita em laboratório.
      </p>
    </>),
  },
  {
    name: 'Blefaroplastia (Correção de Pálpebras Caídas)',
    icon: treatmentIcons.iconBlefaroplastia,
    content: (<>
      <p className='lead'>Procedimento</p>
      <p>
        É a técnica cirúrgica usada para correção do excesso de pele e/ou bolsas
        de gordura que se acumulam sob as pálpebras, concedendo uma melhora
        funcional e estética da região periocular.
        <br /><br />
        No processo de envelhecimento,
        as pálpebras também sofrem ação do tempo, com perda de elasticidade
        cutânea e enfraquecimento muscular.
      </p>
      <div className='line' />
      <p className='lead'>Tratamento</p>
      <p>
        É um procedimento seguro, feito com anestesia local e a cirurgia dura cerca
        de uma hora e meia a duas horas para ser realizada; a recuperação dá-se em
        torno de 5 a 7 dias, e confere ao paciente um olhar com aspecto mais jovial.
      </p>
    </>),
  },
  {
    name: 'Cauterização Química',
    icon: treatmentIcons.iconCauterizacaoQuimica,
    content: (<>
      <p className='lead'>Procedimento</p>
      <p>
        A cauterização química corresponde à terminologia utilizada para descrever a
        aplicação de substâncias ácidas ou cáusticas em determinadas lesões superficiais
        da pele, a fim de removê-las. As substâncias químicas comumente utilizadas
        são o ATA (ácido tricloroacético), ácido nítrico fumegante e fenol.
      </p>
      <div className='line' />
      <p className='lead'>Tratamento</p>
      <p>
        É o tratamento indicado para algumas doenças de origem viral, neoplásicas ou
        inflamatórias (verrugas virais, ceratoses actínicas e seborreicas, granuloma
        piogênico), podendo ser necessária uma ou mais sessões, conforme cada situação.
      </p>
    </>),
  },
  {
    name: 'Cirurgias para Câncer de Pele',
    icon: treatmentIcons.iconCancerDePele,
    content: (<>
      <p className='lead'>Procedimento</p>
      <p>
        O câncer de pele é uma lesão decorrente do crescimento anormal, multiplicado e
        descontrolado das células cutâneas, onde a radiação ultravioleta é o grande agente
        causal, seja por exposição solar crônica ou pelo uso de câmaras de bronzeamento
        artificial.
        <br /><br />
        Alguns fatores contribuem para o seu surgimento como: pele clara,
        história pessoal ou familiar de neoplasias cutâneas, radioterapia ou queimaduras
        prévias e algumas doenças cutâneas pré-existentes. É dividido em não-melanoma e melanoma.
      </p>
      <div className='line' />
      <p className='lead'>Câncer de Pele Não-Melanoma</p>
      <p>
        É o mais frequente tipo de câncer em nosso país, correspondendo cerca de 30% dos cânceres
        diagnosticados. São classificados em carcinoma basocelular (CBC) e carcinoma espinocelular
        (CEC); desenvolvem-se nas áreas expostas ao sol, têm baixa letalidade e índices de cura
        bastante altos, quando diagnosticados prematuramente.
      </p>
      <div className='line' />
      <p className='lead'>Câncer de Pele Melanoma</p>
      <p>
        É mais agressivo, pois apresenta crescimento rápido, além de gerar metástases em órgãos
        e linfonodos. Surge basicamente a partir de uma pinta ou sinal, onde há alteração em seu
        formato, cor e tamanho, podendo ocasionar sangramentos.
        <br /><br />
        Apresenta mais de 90% de chance de cura se detectado e tratado precocemente; com isso,
        vale ressaltar o quão importante é o paciente examinar regularmente sua pele e procurar
        o auxílio de um Dermatologista, caso observe pintas ou sinais suspeitos. A inspeção
        dermatológica deve ser feita ao menos uma vez por ano.
      </p>
      <div className='line' />
      <p className='lead'>Tratamento</p>
      <p>
        É o tratamento indicado para as neoplasias de pele, porém, em algumas situações
        (tipo do câncer, local em que está situado, tamanho da lesão) faz-se necessário a
        aplicação de outros métodos, como Enxertos ou Retalhos, por exemplo.
      </p>
    </>),
  },
  {
    name: 'Cirurgia de Xantelasma',
    icon: treatmentIcons.iconXantelasma,
    content: (<>
      <p className='lead'>Procedimento</p>
      <p>
        O Xantelasma consiste em pequenas placas de gordura depositadas sob a pele, com
        coloração amarelada, e situam-se em especial ao redor dos olhos. Pode estar
        associado ou não à hipercolesterolemia familiar (elevação nos níveis de colesterol).
      </p>
      <div className='line' />
      <p className='lead'>Tratamento</p>
      <p>
        Apesar de ser uma condição benigna, o tratamento do Xantelasma é baseado em fatores
        estéticos, já que a lesão tem tendência de progredir e aumentar de tamanho.
        <br /><br />
        Um dos procedimentos utilizados para sua remoção é a excisão (retirada) cirúrgica,
        feita com anestesia local, e posterior sutura.
      </p>
    </>),
  },
  {
    name: 'Criocirurgia (Crioterapia)',
    icon: treatmentIcons.iconCrioCirurgia,
    content: (<>
      <p className='lead'>Procedimento</p>
      <p>
        É o método terapêutico que possibilita tratar dermatoses benignas ou malignas,
        utilizando o frio. O agente comumente empregado é o Nitrogênio líquido a uma temperatura
        de -195,8ºC, o qual promove a destruição dos tecidos acometidos através do resfriamento.
        <br /><br />
        Indicado no auxílio do tratamento de verrugas virais, lentigos solares (“mancha senil”),
        ceratoses actínicas e seborreicas, vitiligo, dentre outras.
      </p>
    </>),
  },
  {
    name: 'Curetagem',
    icon: treatmentIcons.iconCuretagem,
    content: (<>
      <p className='lead'>Procedimento</p>
      <p>
        A curetagem corresponde à uma raspagem superficial da pele, feita por um instrumental
        chamado cureta. Realizada após a aplicação de anestésico local, é indicada em algumas
        doenças, como Molusco Contagioso, Ceratose Seborréica e Ceratose Actínica.
      </p>
    </>),
  },
  {
    name: 'Desbastamento de Calosidade',
    icon: treatmentIcons.iconDesbastamentoCalosidade,
    content: (<>
      <p className='lead'>Procedimento</p>
      <p>
        O Desbastamento é o nome dado à técnica utilizada para remover calosidades, oriundas
        da produção excessiva de queratina pela camada mais externa da pele, a camada córnea.
        Este excesso geralmente decorre de trauma repetitivo local.
      </p>
      <div className='line' />
      <p className='lead'>Tratamento</p>
      <p>
        Com o auxílio de uma lâmina de bisturi, é realizada uma raspagem cuidadosa na
        superfície da lesão.
      </p>
    </>),
  },
  {
    name: 'Eletrocauterização',
    icon: treatmentIcons.iconEletrocauterizacaoLuzIntensa,
    content: (<>
      <p className='lead'>Procedimento</p>
      <p>
        A Eletrocauterização é um procedimento cirúrgico simples e seguro, realizado
        por um instrumento chamado eletrocautério, que utiliza a junção de calor e eletricidade.
      </p>
      <div className='line' />
      <p className='lead'>Tratamento</p>
      <p>
        É feito sob anestesia local e possui efeitos terapêuticos como retirada, corte
        e coagulação de um tecido alvo.
      </p>
    </>),
  },
  {
    name: 'Enxertos e Retalhos',
    icon: treatmentIcons.iconBiopsiaEnxertosERetalhosExcisaoCirurgica,
    content: (<>
      <p className='lead'>Procedimento</p>
      <p>
        São dois métodos muito utilizados em grandes cirurgias de câncer de pele ou queimaduras,
        onde há perda de pele saudável e a ferida operatória não pode ser diretamente fechada de
        um lado para outro, através da aproximação de suas bordas com pontos cirúrgicos.
        <br /><br />
        Apesar de possuírem indicação similar, há algumas diferenças importantes entre eles:
      </p>
      <div className='line' />
      <p className='lead'>Enxertos</p>
      <p>
        Correspondem quando o fragmento de pele é retirado de uma área do corpo chamada
        “área doadora”, sendo esta, distante da área cirúrgica a ser tratada. Importante frisar
        que não há nenhuma comunicação vascular entre ambas (ela será criada somente mais tarde,
        no decorrer dos dias do pós-operatório), por isso, ele depende da vascularização do
        leito receptor.
      </p>
      <div className='line' />
      <p className='lead'>Retalhos</p>
      <p>
        São realizados em áreas próximas da ferida operatória, onde existe uma comunicação
        entre a área doadora e a receptora através de um pedículo, o qual proporcionará
        suprimento vascular próprio ao local.
      </p>
    </>),
  },
  {
    name: 'Excisão Cirúrgica ',
    icon: treatmentIcons.iconBiopsiaEnxertosERetalhosExcisaoCirurgica,
    content: (<>
      <p className='lead'>Procedimento</p>
      <p>
        É o simples processo de retirada de qualquer lesão, sendo ela benigna ou maligna.
        Realizada sempre sob o efeito de anestesia local.
      </p>
    </>),
  },
  {
    name: 'Lobuloplastia (Correção de Fissura de Lóbulo de Orelha)',
    icon: treatmentIcons.iconLobuloplastia,
    content: (<>
      <p className='lead'>Procedimento</p>
      <p>
        Corresponde ao método cirúrgico utilizado para correção de fissuras dos lóbulos
        de orelha, seja por trauma agudo ou crônico, devido ao excesso de tensão local;
        apenas uma pequena porcentagem corresponde à origem congênita – aquela presente
        ao nascimento.
        <br /><br />
        O procedimento cirúrgico para o reparo é simples, seguro e feito sob anestesia local.
      </p>
    </>),
  },
  {
    name: 'Matricectomia (Correção de Unhas Encravadas)',
    icon: treatmentIcons.iconMicosesOnicocriptoseMatricectomia,
    content: (<>
      <p className='lead'>Procedimento</p>
      <p>
        A onicocriptose – popularmente conhecida como “unha encravada” – é um processo
        inflamatório decorrente da penetração da unha nos tecidos periungueais,
        ocasionando dor e desconforto. Alguns fatores são favoráveis ao encravamento, como:
        <ul>
          <li>Unhas em formato muito curvo;</li>
          <li>Corte feito de maneira irregular e errônea;</li>
          <li>Trauma local;</li>
          <li>Uso de calçados apertados.</li>
        </ul>
      </p>
      <div className='line' />
      <p className='lead'>Tratamento</p>
      <p>
        O procedimento cirúrgico para tratar esta condição é chamado Matricectomia,
        onde, sob anestesia local, a unha é corrigida retirando-se uma parte da matriz,
        de modo que proporcione uma redução em sua largura.
      </p>
    </>),
  },
  {
    name: 'Quimiocirurgia (Peeling de Fenol)',
    icon: treatmentIcons.iconQuimiocirurgiaPeelingQuimico,
    content: (<>
      <p className='lead'>Procedimento</p>
      <p>
        O Peeling de Fenol – também conhecido por Peeling Químico Profundo, auxilia no
        tratamento de manchas e rugas causadas pela exposição solar, fumo e stress,
        amenizando-as.
        <br /><br />
        Este método tem a finalidade de atingir as camadas profundas da pele e eliminar
        a memória celular danificada, gerando um estímulo na produção de colágeno,
        e proporcionando uma pele renovada.
      </p>
    </>),
  },
  {
    name: 'Remoção de Cistos e Lipomas',
    icon: treatmentIcons.iconQueimadurasCistosLipomas,
    content: (<>
      <p className='lead'>Procedimento</p>
      <p>
        Cistos e Lipomas são duas condições dermatológicas benignas e frequentes, que
        acometem qualquer região do corpo, podendo ser únicos ou múltiplos, dolorosos ou não.
      </p>
      <div className='line' />
      <p className='lead'>Cistos</p>
      <p>
        São representados por nódulos cor da pele ou amarelados, com conteúdo composto
        por queratina e sebo.
      </p>
      <div className='line' />
      <p className='lead'>Lipomas</p>
      <p>
        São nódulos formados pelo acúmulo de células de gordura madura, que também
        podem conter tecido fibroso.
      </p>
      <div className='line' />
      <p className='lead'>Tratamento</p>
      <p>
        Em alguns casos, faz-se necessário a remoção cirúrgica dessas lesões, tanto pelo
        comprometimento estético, como funcional. Dependendo do tamanho da lesão e da área
        situada, o procedimento é relativamente simples, feito com anestesia local,
        subsequente incisão, retirada da lesão e, por fim, sutura.
      </p>
    </>),
  },
];

GeneralDefinitions.esteticalDiseases = [
  {
    name: 'Bioestimuladores de Colágeno',
    icon: treatmentIcons.iconColagenoPreenchimentoFacial,
    content: (<>
      <p className='lead'>Procedimento</p>
      <p>
        Como o próprio nome indica, são substâncias responsáveis por auxiliar na
        produção e na manutenção de colágeno, a fim de manter um estoque minimamente
        estável, já que esta proteína (feita de forma natural pelo nosso organismo)
        apresenta tendência de redução em seus níveis, com o decorrer dos anos.
        Promovem uma melhor sustentação e firmeza da pele.
        <br /><br />
        Os bioestimuladores mais conhecidos e utilizados são o ácido poli-l-lático
        e a hidroxiapatita de cálcio.
      </p>
    </>),
  },
  {
    name: 'Escleroterapia',
    icon: treatmentIcons.iconEscleroterapia,
    content: (<>
      <p className='lead'>Procedimento</p>
      <p>
        É um método comumente utilizado para o tratamento de pequenas varizes e teleangiectasias
        de membros inferiores, no qual se injeta cuidadosamente a solução esclerosante,
        através de agulha fina.
        <br /><br />
        O intuito do procedimento é:
        <ul>
          <li>Reduzir a quantidade dos microvasos;</li>
          <li>Promover uma melhora em sua aparência estética. </li>
        </ul>
        Podem ser necessárias uma ou mais aplicações, dependendo de cada caso.
      </p>
    </>),
  },
  {
    name: 'Luz Intensa Pulsada (LIP)',
    icon: treatmentIcons.iconEletrocauterizacaoLuzIntensa,
    content: (<>
      <p className='lead'>Procedimento</p>
      <p>
        A Luz Intensa Pulsada (LIP) corresponde à um aparelho que utiliza feixes
        de luz específicos, os quais contribuem para o tratamento do fotoenvelhecimento.
        <br /><br />
        Através do estímulo térmico emitido pelo equipamento, o local a ser tratado absorve
        a onda de calor através de receptores (chamados cromóforos), o que ocasiona a
        contração e subsequente remodelação das fibras de colágeno da pele.
        <br /><br />
        Além de ser um método auxiliar para o rejuvenescimento, a LIP promove:
        <ul>
          <li>Melhora na textura da pele (no aspecto de manchas e poros);</li>
          <li>
            Suavização de pequenos vasos (telangiectasias), rubor facial (vermelhidão) e olheiras.
          </li>
        </ul>
      </p>
    </>),
  },
  {
    name: 'Mesoterapia Capilar e Corporal',
    icon: treatmentIcons.iconMesoterapiaCapilarMMP,
    content: (<>
      <p className='lead'>Mesoterapia Capilar</p>
      <p>
        Também chamada de Intradermoterapia Capilar, a Mesoterapia Capilar consiste na aplicação
        manual de medicamentos no couro cabeludo, através de uma seringa com agulha fina.
        <br /><br />
        É um método terapêutico importante no tratamento das Alopecias (queda de cabelo), pois
        permite que a medicação aja dentro do folículo piloso, estimulando o tecido local através
        dos seus princípios ativos. Há contribuição para duas situações:
        <ul>
          <li>Promove-se o crescimento dos fios;</li>
          <li>Estabiliza-se a sua queda.</li>
        </ul>
        As aplicações são rápidas, seguras e as sessões são realizadas em um intervalo médio
        de 15 dias.
      </p>
      <div className='line' />
      <p className='lead'>Mesoterapia Corporal</p>
      <p>
        É um procedimento minimamente invasivo, onde são realizadas aplicações intradérmicas
        ou subcutâneas de substâncias (podendo ser medicamentos, aminoácidos, vitaminas),
        indicadas para o tratamento de flacidez cutânea, celulite e estrias.
        <br /><br />
        Na <strong>CELULITE</strong>, a mesoterapia auxilia a fluidificar as células de
        gordura, promovendo uma melhora no edema e circulação sanguínea do local tratado,
        além de proporcionar um aspecto mais homogêneo à pele.
        <br /><br />
        As <strong>ESTRIAS</strong> são oriundas de um estiramento prévio nas fibras colágenas
        e elásticas da pele, seja este por algum fator mecânico, hormonal ou medicamentoso.
        Neste caso, a Intradermoterapia estimula a circulação, a fim de ativar o colágeno
        a preencher as fibras cutâneas que foram inicialmente rompidas.
        <br /><br />
        Para a <strong>FLACIDEZ CUTÂNEA</strong>, as microinjeções ajudam na produção de
        elastina e colágeno, concedendo à área tratada uma maior firmeza e elasticidade.
        <br /><br />
        O número de sessões necessárias, assim como o resultado final, dependem do grau de
        cada doença, da área a ser tratada e da resposta individual do paciente.
      </p>
    </>),
  },
  {
    name: 'Peeling Químico',
    icon: treatmentIcons.iconQuimiocirurgiaPeelingQuimico,
    content: (<>
      <p className='lead'>Procedimento</p>
      <p>
        Procedimento rápido, indolor, seguro e eficaz, o peeling consiste na aplicação de agentes
        químicos (ácidos) com o objetivo:
        <ul>
          <li>Esfoliar;</li>
          <li>Regenerar;</li>
          <li>Melhorar o aspecto da pele.</li>
        </ul>
      </p>
      <div className='line' />
      <p className='lead'>Tratamento</p>
      <p>
        Pode ser aplicado em diferentes níveis de profundidade (superficial, médio ou profundo),
        dependendo da substância a ser utilizada e da indicação individual de cada paciente,
        após avaliação dermatológica minuciosa.
        <br /><br />
        Auxilia no tratamento de manchas, contribuindo para a suavização da aparência de estrias,
        olheiras, além de atenuar cicatrizes acnéicas e rugas.
      </p>
    </>),
  },
  {
    name: 'Preenchimento Facial com Ácido Hialurônico',
    icon: treatmentIcons.iconColagenoPreenchimentoFacial,
    content: (<>
      <p className='lead'>Procedimento</p>
      <p>
        O preenchimento cutâneo é um procedimento médico, no qual injeta-se a substância
        preenchedora (Ácido Hialurônico) na área a ser cuidada, visando:
        <ul>
          <li>Suavizar sulcos, rugas, olheiras e algumas cicatrizes; </li>
          <li>Repor o volume e contorno em certos locais do rosto.</li>
        </ul>
        O ácido hialurônico se faz presente naturalmente em nosso corpo, nas cartilagens
        e articulações, e praticamente em todos os órgãos. O órgão que detém a maior
        porcentagem de concentração deste ácido é a pele (cerca de 56%), responsável por
        manter hidratação e estabilidade adequadas.
        <br /><br />
        Com o passar dos anos, sua quantidade pode diminuir, sendo necessária a reposição.
        <br /><br />
        Realizamos esta técnica com o intuito de preservar e respeitar a anatomia de cada
        paciente, prezando sempre pela naturalidade dos resultados.
        <br /><br />
        Por tratar-se de uma substância totalmente absorvível pelo organismo, a
        durabilidade média do procedimento é em torno de 12 meses.
      </p>
    </>),
  },
  {
    name: 'Toxina Botulínica',
    icon: treatmentIcons.iconToxinaBotulinica,
    content: (<>
      <p className='lead'>Procedimento</p>
      <p>
        Produzida pela bactéria Clostridium botulinum, a Toxina Botulínica é uma proteína
        purificada, responsável por promover o relaxamento muscular da área a ser tratada.
        <br /><br />
        Procedimento minimamente invasivo, é realizado respeitando os músculos e expressões
        faciais de modo individual; os resultados começam a surgir em cerca de 48 horas,
        apresentando um pico de ação em torno de 15 dias.
        <br /><br />
        É amplamente utilizado para:
        <ul>
          <li>Suavizar as rugas de expressão pré-existentes;</li>
          <li>Prevenção contra sulcos e vincos que possam surgir futuramente;</li>
          <li>Tratamento de hiperidrose (sudorese excessiva);</li>
          <li>Tratamento de Sorriso Gengival (exposição aumentada da gengiva ao sorrir);</li>
          <li>Enxaqueca crônica.</li>
        </ul>
        Vale ressaltar que, os resultados dependem de cada organismo, da técnica utilizada,
        dos pontos de aplicação e também do produto aplicado. Seu efeito mantém-se
        em média por até 6 meses.
      </p>
    </>),
  },
  {
    name: 'Tratamentos de Melanose Solar nas Mãos (Mancha Senil)',
    icon: treatmentIcons.iconMelanoseSolar,
    content: (<>
      <p className='lead'>Procedimento</p>
      <p>
        Queixa frequente na rotina do Dermatologista, a Melanose Solar das mãos consiste em
        manchas de coloração acastanhada, localizadas no dorso das mãos e braços.
        As causas mais comuns são predisposição genética e dano sofrido pela pele,
        em consequência de exposição crônica ao sol.
        <br /><br />
        Além do uso do protetor solar e cremes clareadores, podem ser utilizados concomitantemente
        alguns recursos que proporcionam resultados eficazes, como:
        <ul>
          <li>Luz Intensa Pulsada (LIP);</li>
          <li>Peeling Químico;</li>
          <li>Crioterapia (Criocirurgia), em alguns casos.</li>
        </ul>
      </p>
    </>),
  },
  {
    name: 'Tratamento de Olheiras',
    icon: treatmentIcons.iconOlheiras,
    content: (<>
      <p className='lead'>Procedimento</p>
      <p>
        As olheiras são caracterizadas por uma hipercromia cutânea periorbital, ou seja,
        pela presença de coloração arroxeada ou acastanhada na área ao redor dos olhos.
        Pode ter origem por depósito de pigmentos próprios, pele fina ou até mesmo presença
        de pequenos vasos no local.
        <br /><br />
        Há alguns fatores que podem exacerbar essa condição, dentre eles predisposição
        genética, tabagismo, respiração bucal, exposição solar e sono irregular.
        <br /><br />
        Além de cremes e séruns prescritos para uso tópico, há outros tratamentos
        que podem ser associados, como:
        <ul>
          <li>Peeling Químico;</li>
          <li>Preenchimento Cutâneo com Ácido Hialurônico;</li>
          <li>Luz Intensa Pulsada (LIP).</li>
        </ul>
      </p>
    </>),
  },
];

GeneralDefinitions.capilarDiseases = [
  {
    name: 'Microinfusão de Medicamentos na Pele (MMP®)',
    icon: treatmentIcons.iconMesoterapiaCapilarMMP,
    content: (<>
      <p className='lead'>Procedimento</p>
      <p>
        A MMP® é um processo com ação similar à Mesoterapia Capilar, em que a diferença técnica
        consiste em como o procedimento é realizado - a aplicação das substâncias é feita através
        de injetores eletrônicos com múltiplas microagulhas.
      </p>
    </>),
  },
  {
    name: 'Mesoterapia Capilar',
    icon: treatmentIcons.iconAlopeciaMesoterapiaCapilar,
    content: (<>
      <p className='lead'>Procedimento</p>
      <p>
        Também chamada de Intradermoterapia Capilar, a Mesoterapia Capilar consiste na aplicação
        manual de medicamentos no couro cabeludo, através de uma seringa com agulha fina.
        <br /><br />
        É um método terapêutico importante no tratamento das Alopecias (queda de cabelo),
        pois permite que a medicação aja dentro do folículo piloso, estimulando o tecido local
        através dos seus princípios ativos. Há contribuição para duas situações:
        <ul>
          <li>Promove-se o crescimento dos fios;</li>
          <li>Estabiliza-se a sua queda.</li>
        </ul>
      </p>
      <div className='line' />
      <p className='lead'>Tratamento</p>
      <p>
        As aplicações são rápidas, seguras e as sessões são realizadas em um intervalo
        médio de 15 dias.
      </p>
    </>),
  },
  {
    name: 'Tratamentos Clínicos',
    icon: treatmentIcons.iconTratamentosClinicos,
    content: (<>
      <p className='lead'>Procedimento</p>
      <p>
        Após avaliação completa feita na consulta dermatológica, podem ser prescritos
        tratamentos à base de shampoos fortalecedores e anti-queda, soluções tópicas
        e vitaminas orais, além de medicamentos específicos.
      </p>
    </>),
  },
  {
    name: 'Transplante Capilar',
    icon: treatmentIcons.iconTransplanteCapilar,
    content: '',
  },
];

export default GeneralDefinitions;
