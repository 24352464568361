/* eslint-disable react/prop-types */
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styledComponents from 'styled-components';
import THEME from '../../themes';

const EmailContainer = styledComponents.div``;
const InstagramContainer = styledComponents.div``;
const FacebookContainer = styledComponents.div``;
const WhatsAppContainer = styledComponents.div``;
const HumburgerMenuContainer = styledComponents.div`
  padding-top: 5px;
`;
const DropDownIconContainer = styledComponents.div``;
const MiatiIconContainer = styledComponents.div``;

export const EmailIcon = () => (
  <EmailContainer>
    <svg xmlns="http://www.w3.org/2000/svg" width="24.375" height="19.5" viewBox="0 0 24.375 19.5">
      <path id="email-outline" d="M26.375,6.438A2.445,2.445,0,0,0,23.938,4H4.438A2.445,2.445,0,0,0,2,6.438V21.063A2.445,2.445,0,0,0,4.438,23.5h19.5a2.445,2.445,0,0,0,2.438-2.437V6.438m-2.437,0-9.75,6.094L4.438,6.438h19.5m0,14.625H4.438V8.875l9.75,6.094,9.75-6.094Z" transform="translate(-2 -4)" fill="#d0ad67"/>
    </svg>
  </EmailContainer>
);

export const InstagemIcon = ({ color = '#d0ad67' }) => (
  <InstagramContainer>
    <svg id="botao-instagram" xmlns="http://www.w3.org/2000/svg" width="25" height="24.997" viewBox="0 0 25 24.997">
      <defs>
        <clipPath id="clip-path">
          <path id="Caminho_2253" data-name="Caminho 2253" d="M0,17.138H25v-25H0Z" transform="translate(0 7.859)" fill={color}/>
        </clipPath>
      </defs>
      <g id="Grupo_2373" data-name="Grupo 2373" clipPath="url(#clip-path)">
        <g id="Grupo_2370" data-name="Grupo 2370" transform="translate(-0.001 -0.003)">
          <path id="Caminho_2250" data-name="Caminho 2250" d="M12.609,1.635a4.23,4.23,0,0,1,4.224,4.224V17.641a4.23,4.23,0,0,1-4.224,4.224H.827A4.23,4.23,0,0,1-3.4,17.641V5.859A4.23,4.23,0,0,1,.827,1.635Zm0-2.385H.827A6.612,6.612,0,0,0-5.782,5.859V17.641A6.61,6.61,0,0,0,.827,24.25H12.609a6.608,6.608,0,0,0,6.609-6.609V5.859A6.61,6.61,0,0,0,12.609-.75" transform="translate(5.782 0.75)" fill={color}/>
        </g>
        <g id="Grupo_2371" data-name="Grupo 2371" transform="translate(5.909 5.905)">
          <path id="Caminho_2251" data-name="Caminho 2251" d="M4.518,1.635A4.208,4.208,0,1,1,.31,5.844,4.213,4.213,0,0,1,4.518,1.635m0-2.385a6.592,6.592,0,1,0,6.59,6.594A6.59,6.59,0,0,0,4.518-.75" transform="translate(2.072 0.75)" fill={color}/>
        </g>
        <g id="Grupo_2372" data-name="Grupo 2372" transform="translate(17.334 4.036)">
          <path id="Caminho_2252" data-name="Caminho 2252" d="M2.41,1.206A1.757,1.757,0,1,1,.651-.553,1.759,1.759,0,0,1,2.41,1.206" transform="translate(1.105 0.553)" fill={color}/>
        </g>
      </g>
    </svg>
  </InstagramContainer>
);

export const FacebokIcon = () => (
  <FacebookContainer>
    <svg id="botao-facebook" xmlns="http://www.w3.org/2000/svg" width="25" height="24.997" viewBox="0 0 25 24.997">
      <defs>
        <clipPath id="clip-path">
          <path id="Caminho_2256" data-name="Caminho 2256" d="M0,17.138H25v-25H0Z" transform="translate(0 7.859)" fill="#d0ad67"/>
        </clipPath>
      </defs>
      <g id="Grupo_2377" data-name="Grupo 2377" clipPath="url(#clip-path)">
        <g id="Grupo_2375" data-name="Grupo 2375" transform="translate(7.799 3.719)">
          <path id="Caminho_2254" data-name="Caminho 2254" d="M0,6.444H2.688V14.6H6.043V6.444H9.4V3.085H6.043V1.38c0-.566.363-1.031.671-1.031H9.4v-3.3H6.714A4.221,4.221,0,0,0,2.688,1.434V3.085H0Z" transform="translate(0 2.955)" fill="#d0ad67"/>
        </g>
        <g id="Grupo_2376" data-name="Grupo 2376" transform="translate(-0.001 -0.003)">
          <path id="Caminho_2255" data-name="Caminho 2255" d="M12.609,1.635a4.23,4.23,0,0,1,4.224,4.224V17.641a4.23,4.23,0,0,1-4.224,4.224H.827A4.23,4.23,0,0,1-3.4,17.641V5.859A4.23,4.23,0,0,1,.827,1.635Zm0-2.385H.827A6.612,6.612,0,0,0-5.782,5.859V17.641A6.61,6.61,0,0,0,.827,24.25H12.609a6.608,6.608,0,0,0,6.609-6.609V5.859A6.61,6.61,0,0,0,12.609-.75" transform="translate(5.782 0.75)" fill="#d0ad67"/>
        </g>
      </g>
    </svg>
  </FacebookContainer>
);

export const WhatsappIcon = ({ color = THEME.COLORS.WHITE }) => (
  <WhatsAppContainer>
    <svg id="Grupo_2384" data-name="Grupo 2384" xmlns="http://www.w3.org/2000/svg" width="24.997" height="24.996" viewBox="0 0 24.997 24.996">
      <g id="botao-whatsapp">
        <path id="Caminho_2257" data-name="Caminho 2257" d="M628.472,772.2c-.205.307-.8,1-.983,1.21s-.363.219-.673.078a8.45,8.45,0,0,1-2.5-1.547,9.45,9.45,0,0,1-1.729-2.156c-.3-.527.334-.6.915-1.7a.574.574,0,0,0-.026-.545c-.079-.157-.7-1.688-.96-2.3s-.507-.531-.7-.531a1.548,1.548,0,0,0-1.425.358c-1.681,1.847-1.257,3.753.182,5.78,2.826,3.7,4.333,4.381,7.087,5.327a4.315,4.315,0,0,0,1.958.127,3.207,3.207,0,0,0,2.1-1.485,2.581,2.581,0,0,0,.188-1.484C631.824,773.188,628.767,771.753,628.472,772.2Z" transform="translate(-613.073 -758.067)" fill={color}/>
        <path id="Caminho_2258" data-name="Caminho 2258" d="M634.667,761.9c-8.008-7.741-21.261-2.127-21.267,8.795a12.32,12.32,0,0,0,1.662,6.192L613.3,783.3l6.6-1.721a12.468,12.468,0,0,0,18.4-10.883,12.285,12.285,0,0,0-3.64-8.761Zm1.544,8.767a10.371,10.371,0,0,1-15.636,8.857l-.374-.223-3.906,1.015,1.047-3.8-.249-.391a10.33,10.33,0,0,1,16.088-12.743,10.2,10.2,0,0,1,3.031,7.281Z" transform="translate(-613.296 -758.304)" fill={color}/>
      </g>
    </svg>
</WhatsAppContainer>
);

export const HumburgerIcon = ({ color = THEME.COLORS.PRIMARY }) => (
  <HumburgerMenuContainer>
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="16.667" viewBox="0 0 25 16.667">
      <path id="menu" d="M3,6H28V8.778H3V6m0,6.944H28v2.778H3V12.944m0,6.944H28v2.778H3Z" transform="translate(-3 -6)" fill={ color }/>
    </svg>
  </HumburgerMenuContainer>
);

export const DropDownArrow = ({ color = THEME.TEXT.BODY.COLOR }) => (
  <DropDownIconContainer>
    <svg xmlns="http://www.w3.org/2000/svg" width="17.101" height="8.551" viewBox="0 0 17.101 8.551">
      <g transform="translate(0 0)">
        <path d="M0,63.75,8.551,72.3,17.1,63.75Z" transform="translate(0 -63.75)" fill={ color } />
      </g>
    </svg>
  </DropDownIconContainer>
);

export const MiatiIcon = ({ style }) => (
  <MiatiIconContainer style={ style }>
    <StaticImage src="../../images/por-miati-branco3.png" alt="Feito por www.miati.com.br" placeholder="blurred" quality={100}/>
  </MiatiIconContainer>
);
