import { Link } from 'gatsby';
import React from 'react';
import styledComponents from 'styled-components';
import THEME from '../../themes';
import { LogoNoPositioning, LogoReduced } from '../logo';

const LogoBackDrop = styledComponents.div`
  top: -15px;
  right: calc(100% - 130px);
  position: absolute;
  width: 400%;
  height: 108px;
  background: ${THEME.COLORS.WHITE} 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 0px 100px 100px 0px;

  @media screen and (max-width: 599px){
    display: none;
  }
`;

const LogoBackDropMobile = styledComponents.div`
  top: -15px;
  right: calc(100% - 75px);
  position: absolute;
  width: 400%;
  height: 70px;
  background: ${THEME.COLORS.WHITE} 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 0px 100px 100px 0px;

  @media screen and (min-width: 600px){
    display: none;
  }
`;

const LogoContainer = styledComponents.div`
  position: absolute;
  width: 440px;
  top: -54px;
  right: -300px;

  a {
    display: block;
    position: absolute;
    z-index: 31;
    top: 61px;
    left: 30px;
    width: 370px;
    height: 84px;
    opacity: 0;
  }
`;

const LogoContainerMobile = styledComponents.div`
  position: absolute;
  width: 140px;
  top: -35px;
  right: -38px;

  a {
    display: block;
    position: absolute;
    z-index: 31;
    top: 30px;
    left: 5px;
    width: 100px;
    height: 84px;
    opacity: 0;
  }
`;

export const Logo = () => (
  <>
    <h1 style={{
      display: 'block',
      width: 1,
      height: 1,
      margin: 0,
      padding: 0,
      overflow: 'hidden',
      position: 'absolute',
    }}>
      Clínica Dermatológica Angelucci
    </h1>
    <LogoBackDrop>
        <LogoContainer>
          <Link to='/'> Home </Link>
          <LogoNoPositioning />
        </LogoContainer>
    </LogoBackDrop>
    <LogoBackDropMobile>
      <LogoContainerMobile>
        <Link to='/'> Home </Link>
        <LogoReduced />
      </LogoContainerMobile>
    </LogoBackDropMobile>
  </>
);
