import iconCirurgica from '../../images/icons/Icon-Cirurgica.svg';
import iconEstetica from '../../images/icons/Icon-Estetica.svg';
import iconCapilar from '../../images/icons/Icon-Capilar.svg';
import iconClinica from '../../images/icons/IconClinica.svg';
import iconAbscessosECeratoseSeborreica from '../../images/icons/Varias14.svg';
import iconAcne from '../../images/icons/Acne.svg';
import iconAlergiasDermatiteAtopicaDisidrose from '../../images/icons/Varias15.svg';
import iconAlopeciaMesoterapiaCapilar from '../../images/icons/Varias04.svg';
import iconCancerDePele from '../../images/icons/Varias11.svg';
import iconCicatrizesHipertroficasECelulite from '../../images/icons/Varias10.svg';
import iconCeratoseAcniticaVerrugas from '../../images/icons/Varias02.svg';
import iconDermatiteContato from '../../images/icons/Dermatite-Contato.svg';
import iconDermatiteSeborreica from '../../images/icons/Dermatite-Seborreica.svg';
import iconHerpes from '../../images/icons/Herpes.svg';
import iconLupusMoluscoPsoriaseUrticarias from '../../images/icons/Varias01.svg';
import iconMelanoses from '../../images/icons/Melanoses.svg';
import iconMicosesOnicocriptoseMatricectomia from '../../images/icons/Varias13.svg';
import iconQueimadurasCistosLipomas from '../../images/icons/Varias09.svg';
import iconRosacea from '../../images/icons/Rosacea.svg';
import iconVitiligo from '../../images/icons/Vitiligo.svg';
import iconBiopsiaEnxertosERetalhosExcisaoCirurgica from '../../images/icons/Varias08.svg';
import iconBlefaroplastia from '../../images/icons/Blefaroplastia.svg';
import iconCauterizacaoQuimica from '../../images/icons/Cauterizacao-Quimica.svg';
import iconXantelasma from '../../images/icons/Xantelasma.svg';
import iconCrioCirurgia from '../../images/icons/CrioCirurgia.svg';
import iconCuretagem from '../../images/icons/Curetagem.svg';
import iconDesbastamentoCalosidade from '../../images/icons/Varias12.svg';
import iconEletrocauterizacaoLuzIntensa from '../../images/icons/Varias07.svg';
import iconLobuloplastia from '../../images/icons/Lobuloplastia.svg';
import iconQuimiocirurgiaPeelingQuimico from '../../images/icons/Varias06.svg';
import iconColagenoPreenchimentoFacial from '../../images/icons/Varias05.svg';
import iconEscleroterapia from '../../images/icons/Escleroterapia.svg';
import iconMesoterapiaCapilarMMP from '../../images/icons/Varias03.svg';
import iconToxinaBotulinica from '../../images/icons/Toxina-Botulinica.svg';
import iconMelanoseSolar from '../../images/icons/Mancha-Senil.svg';
import iconOlheiras from '../../images/icons/Olheiras.svg';
import iconTratamentosClinicos from '../../images/icons/Tratamentos-Clinicos.svg';
import iconTransplanteCapilar from '../../images/icons/Transplante-Capilar.svg';

export default {
  iconCirurgica,
  iconEstetica,
  iconCapilar,
  iconClinica,
  iconAbscessosECeratoseSeborreica,
  iconAcne,
  iconAlergiasDermatiteAtopicaDisidrose,
  iconAlopeciaMesoterapiaCapilar,
  iconCancerDePele,
  iconCicatrizesHipertroficasECelulite,
  iconCeratoseAcniticaVerrugas,
  iconDermatiteContato,
  iconDermatiteSeborreica,
  iconHerpes,
  iconLupusMoluscoPsoriaseUrticarias,
  iconMelanoses,
  iconMicosesOnicocriptoseMatricectomia,
  iconQueimadurasCistosLipomas,
  iconRosacea,
  iconVitiligo,
  iconBiopsiaEnxertosERetalhosExcisaoCirurgica,
  iconBlefaroplastia,
  iconCauterizacaoQuimica,
  iconXantelasma,
  iconCrioCirurgia,
  iconCuretagem,
  iconDesbastamentoCalosidade,
  iconEletrocauterizacaoLuzIntensa,
  iconLobuloplastia,
  iconQuimiocirurgiaPeelingQuimico,
  iconColagenoPreenchimentoFacial,
  iconEscleroterapia,
  iconMesoterapiaCapilarMMP,
  iconToxinaBotulinica,
  iconMelanoseSolar,
  iconOlheiras,
  iconTratamentosClinicos,
  iconTransplanteCapilar,
};
