/* eslint-disable react/prop-types */
import React from 'react';
import 'normalize.css';
import styledComponents from 'styled-components';
import Helmet from 'react-helmet';
import THEME from '../../themes';
import { Header } from '../header';
import { Footer } from '../footer';

const LayoutContainer = styledComponents.div`
  background: ${THEME.COLORS.GRAY4} 0% 0% no-repeat padding-box;
  width: 100%; 
  height: 100%; 
  position: relative;
  padding-bottom: 342px;
  padding-top: 156px;
  overflow: hidden;
  font: normal normal normal 16px/26px PT Sans;
  color: ${THEME.TEXT.BODY.COLOR};

  @media screen and (max-width: 983px){
    padding-bottom: 640px;
  }
`;

export const Layout = ({ title, children, meta }) => (
  <LayoutContainer>
    <Helmet
      title={title || 'Clínica Dermatológica Angelucci'}
    >
      <html lang="pt-BR" />
      {meta}
    </Helmet>
    <Header />
      {children}
    <Footer />
  </LayoutContainer>
);
