import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styledComponents from 'styled-components';

const LogoFullContainer = styledComponents.div``;
const LogoNoPositioningContainer = styledComponents.div``;
const LogoReducedContainer = styledComponents.div``;

// eslint-disable-next-line react/prop-types
export const LogoFull = ({ style }) => (
    <LogoFullContainer style={style}>
        <StaticImage src='../../images/logo/full.png' alt="Logo Clínica Dermatológica Angelucci" placeholder="blurred" quality={100}/>
    </LogoFullContainer>
);

export const LogoNoPositioning = () => (
    <LogoNoPositioningContainer>
      <StaticImage src='../../images/logo/noPositioning.png' alt="Logo Clínica Dermatológica Angelucci" placeholder="blurred"/>
    </LogoNoPositioningContainer>
);

export const LogoReduced = () => (
    <LogoReducedContainer>
        <StaticImage src='../../images/logo/reduced.png' alt="Logo Clínica Dermatológica Angelucci" placeholder="blurred"/>
    </LogoReducedContainer>
);
