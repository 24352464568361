/* eslint-disable no-undef */

export const getCurrentURL = () => (
  typeof window !== 'undefined' ? window.location.href : ''
);

export const clearURL = (value) => {
  let result = value;

  if (value.indexOf('?') !== -1) result = result.substring(0, result.indexOf('?'));

  if (value.endsWith('/')) result = result.substring(0, result.length - 1);

  return result;
};

export const actualPathEndsWith = (value) => (
  clearURL(getCurrentURL()).endsWith(value)
);

export const actualPathContains = (value) => (
  getCurrentURL().indexOf(value) > -1
);

export const blockScroll = () => {
  if (typeof document !== 'undefined') {
    document.querySelector('body').style.setProperty('overflow', 'hidden');
  }
};

export const unblockScroll = () => {
  if (typeof document !== 'undefined') {
    document.querySelector('body').style.setProperty('overflow', 'initial');
  }
};

export const changeVisibility = (item) => {
  if (typeof document !== 'undefined') {
    document.querySelector(item)
      .style.setProperty('display', document.querySelector(item).style.display !== 'block' ? 'block' : 'none');
  }
};

export const timedChangeVisibility = (item, time) => {
  if (typeof document !== 'undefined') {
    const itemToManipulate = document.querySelector(item);

    if (itemToManipulate.style.display === 'block') {
      setTimeout(() => itemToManipulate.style.setProperty('display', 'none'), time);
    } else {
      itemToManipulate.style.setProperty('display', 'block');
    }
  }
};

export const animateExibition = (item, height = 'auto') => {
  if (typeof document !== 'undefined') {
    const itemToManipulate = document.querySelector(item);

    if (itemToManipulate.style.display === 'block') {
      setTimeout(() => itemToManipulate.style.setProperty('display', 'none'), 300);
      itemToManipulate.style.setProperty('opacity', itemToManipulate.style.opacity !== '1' ? '1' : '0');
      itemToManipulate.style.setProperty('height', itemToManipulate.style.height !== height ? height : '0px');
    } else {
      itemToManipulate.style.setProperty('display', 'block');
      setTimeout(() => {
        itemToManipulate.style.setProperty('opacity', itemToManipulate.style.opacity !== '1' ? '1' : '0');
        itemToManipulate.style.setProperty('height', itemToManipulate.style.height !== height ? height : '0px');
      }, 200);
    }
  }
};
