/* eslint-disable react/prop-types */
import React from 'react';
import styledComponents from 'styled-components';
import THEME from '../../themes';
import { EmailIcon, FacebokIcon, InstagemIcon } from '../icons';

const SocialMenuContainer = styledComponents.ul`
list-style: none;
display: flex;
justify-content: space-between;
width: 90px;
padding-top: 15px;

li {
  color: ${THEME.TEXT.BODY.COLOR};
  font: normal normal normal 22px/32px ${THEME.TEXT.FONT_NAME};
  display: flex;
  justify-content: space-between;

  * {
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 599px){
  padding-top: 0px;
}
`;

export const SocialMenu = ({ style }) => (
  <SocialMenuContainer style={style}>
    <li><a href='mailto:dermatologiaangelucci@gmail.com' target='_blank' rel="noreferrer"><EmailIcon /></a></li>
    <li><a href='https://www.instagram.com/dermatologiaangelucci/' target='_blank' rel="noreferrer"><InstagemIcon /></a></li>
    <li><a href='https://www.facebook.com/dermatologiaangelucci' target='_blank' rel="noreferrer"><FacebokIcon/></a></li>
  </SocialMenuContainer>
);
