export const arrayToolsFor = (arrayContent) => ({
  group: (data, by) => arrayContent.reduce(
    (acc, content) => {
      if (!acc[content[by]]) acc[content[by]] = [];
      acc[content[by]].push(content[data]);
      return acc;
    },
    {},
  ),
});
