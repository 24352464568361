import React from 'react';
import styledComponents from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import THEME from '../../themes';
import { actualPathContains, actualPathEndsWith, animateExibition } from '../../utilities/windowManagement';
import { DropDownArrow, HumburgerIcon, WhatsappIcon } from '../icons';
import { SocialMenu } from '../socialMenu';

const Navigation = styledComponents.nav`
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  max-width: ${THEME.GENERAL.CONTENT_LIMIT};
  padding-left: ${THEME.GENERAL.MIN_PADDING};
`;

const SiteMenuContainer = styledComponents.ul`
  list-style: none;
  display: flex;
  justify-content: space-between;
  width: 380px;
  padding-top: 15px;
  text-align: center;

  li {
    padding-right: 10px;
    color: ${THEME.TEXT.BODY.COLOR};
    font: normal normal normal 22px/32px ${THEME.TEXT.FONT_NAME};
    display: flex;
    justify-content: space-between;

    #subTreatments {
      position: absolute;
      background-color: #fff;
      top: 70px;
      padding: 16px;
      border: 1px solid #ccc;
      display: none;
      opacity: 0;
      height: 0px;
      transition: all .2s linear;

      a {
        font: normal normal normal 16px/21px PT Sans;
      }
    }
  }

  a {
    display: flex;
    cursor: pointer;
  }

  a, a:VISITED {
    color: ${THEME.TEXT.BODY.COLOR};
    text-decoration: none;
  }

  a.selected {
    color: ${THEME.COLORS.PRIMARY};
    font-weigth: bold;
  }

  a:HOVER {
    color: ${THEME.COLORS.PRIMARY2};
  }

  &.vertical {
    position: fixed;
    width: 100%;
    top: 80px;
    left: 0;
    opacity: 0;
    height: 0px;
    display: none;
    background-color: #fff;
    z-index: -1;
    flex-wrap: wrap;
    text-align: center;
    padding-bottom: 30px;
    padding-top: 100px;
    margin: 0;
    padding-left: 8px;
    transition: all .2s linear;
    overflow: hidden;

    li {
      width: calc(100% - 18px);
      text-align: center;
      padding: 0;
      margin: 0;
      flex-wrap: wrap;
      line-height: 40px;

      a {
        display: block;
        margin: 5px auto;
        box-shadow: 0px 6px 9px #81818180;
        width: 100%;
      }

      & > ul {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        border: 1px solid #CCC;
        width: 100%;
        margin: 5px auto;

        li {
          width: 100%;
          justify-content: center;
          font: normal normal normal 16px/21px PT Sans;
          line-height: 30px;

          a {
            box-shadow: none;
          }
        }
      }
    }

    @media screen and (min-width: 1025px){
      display: none !important;
    }
  }

  @media screen and (max-width: 1024px){
    display: none;
  }
`;

const SiteMenuMobileContainer = styledComponents.div`
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #81818180;
  border-radius: 20px;
  opacity: 1;
  width: 40px;
  height: 40px;
  margin-left: 25px;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 1025px){
    display: none;
  }

  @media screen and (max-width: 599px){
    margin-top: 5px;
  }
`;

const WhatsappContainer = styledComponents.div`
  max-width: 40px;
  max-height: 50px;
  background: ${THEME.COLORS.PRIMARY} 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 1px 3px #A77E2D, 0px 3px 6px #81818180;
  border-radius: 0px 0px 20px 20px;
  padding-top: 15px;
  padding-bottom: 5px;
  padding-left: 8px;
  padding-right: 8px;
  overflow: hidden;
`;

const Routes = {
  toClinic: '/a-clinica',
  toTreatments: '/tratamentos',
  toClinicalDermatology: '/tratamentos/dermatologia_clinica',
  toCirurgicalDermatology: '/tratamentos/dermatologia_cirurgica',
  toEsteticalDermatology: '/tratamentos/dermatologia_estetica',
  toCapilarTreatment: '/tratamentos/tratamentos_capilares',
  toContact: '/contato',
};

const getClassNameForRoute = (route) => (actualPathEndsWith(route) ? 'selected' : '');
const getClassNameForRouteContaining = (route) => (actualPathContains(route) ? 'selected' : '');
const getArrawColor = (route) => (
  actualPathContains(route) ? THEME.COLORS.PRIMARY : THEME.TEXT.BODY.COLOR
);

// eslint-disable-next-line react/prop-types
const SiteMenu = ({ vertical = false, id }) => (
  <SiteMenuContainer className={vertical ? 'vertical' : ''} id={id}>
    <li>
      <AniLink fade bg="white" to={Routes.toClinic} className={getClassNameForRoute(Routes.toClinic)}>
        A Clínica
      </AniLink>
    </li>
    <li>
       { !vertical ? <a onClick={() => animateExibition('#subTreatments')} className={getClassNameForRouteContaining(Routes.toTreatments)}>
         Tratamentos<span style={{ width: 8 }}></span>
          <DropDownArrow color={getArrawColor(Routes.toTreatments)}/>
        </a>
         : <AniLink fade bg="white" to={Routes.toTreatments} className={getClassNameForRouteContaining(Routes.toTreatments)}>
         Tratamentos<span style={{ width: 8 }}></span>
        </AniLink>
       }

        <ul id={!vertical ? 'subTreatments' : 'mobSubTreatments'}>
          <li>
            <AniLink fade bg="white" to={Routes.toClinicalDermatology} className={getClassNameForRouteContaining(Routes.toClinicalDermatology)}>
              Dermatologia Clínica
            </AniLink>
          </li>
          <li>
            <AniLink fade bg="white" to={Routes.toCirurgicalDermatology} className={getClassNameForRouteContaining(Routes.toCirurgicalDermatology)}>
              Dermatologia Cirúrgica
            </AniLink>
          </li>
          <li>
            <AniLink fade bg="white" to={Routes.toEsteticalDermatology} className={getClassNameForRouteContaining(Routes.toEsteticalDermatology)}>
              Dermatologia Estética
            </AniLink>
          </li>
          <li>
            <AniLink fade bg="white" to={Routes.toCapilarTreatment} className={getClassNameForRouteContaining(Routes.toCapilarTreatment)}>
              Tratamentos Capilares
            </AniLink>
          </li>
        </ul>
      </li>
    <li>
      <AniLink fade bg="white" to={Routes.toContact} className={getClassNameForRoute(Routes.toContact)}>
        Contato
      </AniLink>
    </li>
  </SiteMenuContainer>
);

const SiteMenuMobile = () => (
  <SiteMenuMobileContainer>
    <a onClick={() => animateExibition('#moboMenu')} style={{ cursor: 'pointer' }}>
      <HumburgerIcon />
    </a>
  </SiteMenuMobileContainer>
);

export const Menu = () => (
  <Navigation>
    <SiteMenu />
    <SocialMenu />
    <SiteMenuMobile />
    <SiteMenu vertical={true} id='moboMenu' />
  </Navigation>
);

export const WhatsappButton = () => (
  <WhatsappContainer>
    <WhatsappIcon />
  </WhatsappContainer>
);
