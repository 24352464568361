import React from 'react';
import styledComponents from 'styled-components';
import THEME from '../../themes';
import { Divisor } from '../divisor';
import { MiatiIcon } from '../icons';
import { LogoFull } from '../logo';
import { SocialMenu } from '../socialMenu';
import { FooterUnits } from './units';

const FooterContainer = styledComponents.footer`
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: ${THEME.COLORS.WHITE};
  padding-bottom: 40px;
  padding-left: 16px
  padding-right: 16px
`;

const FooterContent = styledComponents.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
  width: 100%;
  max-width: calc(${THEME.GENERAL.CONTENT_LIMIT} - 630px);
  padding-top: 40px;
  flex-wrap: wrap;
`;

const LogoAndSocial = styledComponents.div`
  width: 100%;
  max-width: 444px;
  text-align: right;
  padding-top: 0px;
  overflow: hidden;

  & > div {
    width: 444px; 
    margin: 0 auto;
  }

  @media screen and (max-width: 490px){
    margin: 0 24px 0 24px;
    & > div {
      width: 100%; 
      max-width: 444px;
      margin: 0;
    }
  }
`;

const Units = styledComponents.div`
  width: 100%;
  max-width: 444px;

  @media screen and (max-width: 490px){
    margin: 0 24px 0 24px;
  }
`;

const ContentDivisor = styledComponents.div`
  height: 250px;
  width: 1px;
  margin: 0 40px 0 40px;
  padding: 0;
  background-color: ${THEME.COLORS.GRAY3};
  display: block;

  @media screen and (max-width: 983px){
    height: 1px;
    width: 70%;
    margin: 40px 0 40px 0;
  }
`;

const ByMiati = styledComponents.a`
  position: absolute;
  bottom: 15px;
  right: calc(50% - 464px);
  border: 0;
  text-decoration: none;
  display: block;
  cursor: pointer;

  @media screen and (max-width: 970px){
    right: 16px;
  }
`;

export const Footer = () => (
  <FooterContainer>
    <Divisor />
    <FooterContent>
      <LogoAndSocial>
        <LogoFull style={{ zIndex: 0 }}/>
        <SocialMenu style={{ margin: '0 auto', zIndex: 1, position: 'relative' }}/>
      </LogoAndSocial>
      <ContentDivisor />
      <Units>
        <FooterUnits />
      </Units>
    </FooterContent>
    <ByMiati href="https://www.miati.com.br" target="_blank">
      <MiatiIcon/>
    </ByMiati>
  </FooterContainer>
);
