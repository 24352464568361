import React from 'react';
import styledComponents from 'styled-components';
import GeneralDefinitions from '../../config/generalDefinitions';
import { arrayToolsFor } from '../../utilities/dataManagement';
import THEME from '../../themes';

const { units } = GeneralDefinitions;

const getPhoneStringFrom = (phones) => {
  const phonesGroup = arrayToolsFor(phones).group('number', 'ddd');

  return Object.keys(phonesGroup)
    .reduce((acc, ddd) => `${acc} (${ddd}) ${phonesGroup[ddd].join(' / ')}`, '');
};

const getUnitsTextForHeader = () => units
  .map((unit) => (`${unit.name} | ${getPhoneStringFrom(unit.phones)}`))
  .join(' • ');

const UnitsContainer = styledComponents.div`
  max-width: calc(${THEME.GENERAL.CONTENT_LIMIT} - 16px);
  padding-right: ${THEME.GENERAL.MIN_PADDING};
  padding-left: ${THEME.GENERAL.MIN_PADDING};
  margin: 0 auto;
  text-align: right;
  font: ${THEME.TEXT.BODY.FONT};
  color: ${THEME.TEXT.BODY.COLOR};
  line-height: 36px;

  @media screen and (max-width: 1024px){
    visibility: hidden;
    font-size: 1px;
  }
`;

export const Units = () => (
  <UnitsContainer>{getUnitsTextForHeader()}</UnitsContainer>
);
