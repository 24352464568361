import React from 'react';
import styledComponents from 'styled-components';
import THEME from '../../themes';
import { Divisor } from '../divisor';
import { openWP, WhatsappModal } from '../modal/whatsappModal';
import { Logo } from './logo';
import { Menu, WhatsappButton } from './menu';
import { Units } from './units';

const HeaderContainer = styledComponents.header`
  position: fixed;
  top: 0px;
  width: 100%;
  background-color: ${THEME.COLORS.WHITE};
  min-height: 118px;
  margin-bottom: 60px;
  z-index: 3;

  @media screen and (max-width: 599px){
    min-height: 72px;
  }
`;

const NavContainer = styledComponents.div`
  position: relative;
  margin: 0 auto;
  max-width: ${THEME.GENERAL.CONTENT_LIMIT};
  overflow: visible;
  padding-right: ${THEME.GENERAL.MIN_PADDING};


  @media screen and (max-width: 599px){
    height: 52px;
  }
`;

const WhatsappContainer = styledComponents.div`
  position: absolute;
  top: 79px;
  right: 16px; 

  @media screen and (max-width: 599px){
    top: 52px;
  }
`;

export const Header = () => (
  <>
    <HeaderContainer>
      <Units />
      <Divisor />
      <NavContainer>
        <Logo />
        <Menu />
        <WhatsappContainer>
          <a onClick={openWP}><WhatsappButton /></a>
        </WhatsappContainer>
        <WhatsappModal />
      </NavContainer>
    </HeaderContainer>
  </>
);
