import React from 'react';
import styledComponents from 'styled-components';
import GeneralDefinitions from '../../config/generalDefinitions';
import { arrayToolsFor } from '../../utilities/dataManagement';
import THEME from '../../themes';

const { units } = GeneralDefinitions;

const getPhoneStringFrom = (phones) => {
  const phonesGroup = arrayToolsFor(phones).group('number', 'ddd');

  return Object.keys(phonesGroup)
    .reduce((acc, ddd) => `${acc} (${ddd}) ${phonesGroup[ddd].join(' / ')}`, '');
};

const UnitsContainer = styledComponents.div`
  text-align: left;
  font: ${THEME.TEXT.BODY.FONT};
  color: ${THEME.TEXT.BODY.COLOR};
`;

const UnitName = styledComponents.div`
  font: ${THEME.TEXT.LEAD_TOPIC.FONT};
  text-transform: ${THEME.TEXT.LEAD_TOPIC.TEXT_TRANSFORM};
`;
const UnitAddress = styledComponents.div``;
const UnitPhones = styledComponents.div``;
const MapLink = styledComponents.a`
  text-decoration: underline;
  text-transform: uppercase;
  color: ${THEME.COLORS.PRIMARY};
  font-weight: bold;
  cursor: pointer;

  &:HOVER {
    color: ${THEME.COLORS.PRIMARY2};
  }
`;

const Unit = styledComponents.div`
  margin-bottom: 20px;
`;

const createUnit = (unit) => (
  <Unit key={unit.name}>
    <UnitName>{unit.name}</UnitName>
    <UnitAddress>{unit.address}</UnitAddress>
    <UnitPhones>{getPhoneStringFrom(unit.phones)}</UnitPhones>
    <MapLink href={unit.mapLink} target='_blank' rel="noreferrer">ver no mapa</MapLink>
  </Unit>
);

export const FooterUnits = () => (
  <UnitsContainer>
    {units.map(createUnit)}
  </UnitsContainer>
);
