const THEME = {
  GENERAL: {
    CONTENT_LIMIT: '1600px',
    MIN_PADDING: '16px',
  },
};

THEME.COLORS = {
  BRAND: {
    BLUE: '#241F59',
    GOLD: '#D0AD67',
    BLUE_ALTERNATIVE: '#76B4CF',
    SALMON: '#FFA57D',
  },
  GRAY1: '#6D6D6D',
  GRAY2: '#BEBEBE',
  GRAY3: '#D3D3D3',
  GRAY4: '#ECECEC',
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  SHADOW: '#685734',
  PRIMARY: '#D0AD67',
  PRIMARY2: '#E5C896',
  PRIMARY3: '#FFEAC5',
  SECONDARY: '#241F59',
  SECONDARY2: '#074989',
  SECONDARY3: '#76B4CF',
  SUCCESS: '#067D54',
  SUCCESS2: '#099A43',
  SUCCESS3: '#0FC28B',
  INFO: '#7A09BB',
  INFO2: '#A262C6',
  INFO3: '#E296D5',
  WARNING: '#FF8B07',
  WARNING2: '#FFBD07',
  WARNING3: '#FEF84B',
  ERROR: '#FC3C00',
  ERROR2: '#FFA57D',
  ERROR3: '#FDD1B0',

};

THEME.TEXT = {};
THEME.TEXT.FONT_NAME = 'PT Sans';

THEME.TEXT.BODY = {
  COLOR: THEME.COLORS.GRAY1,
  DEFAULT_ALIGN: 'left',
  FONT: `normal normal 300 16px/26px ${THEME.TEXT.FONT_NAME}`,
  LETTER_SPACING: '0px',
  TEXT_TRANSFORM: 'none',
};

THEME.TEXT.H1 = {
  COLOR: THEME.COLORS.PRIMARY,
  COLOR_ALTERNATIVE: THEME.COLORS.BLACK,
  DEFAULT_ALIGN: 'left',
  FONT: `normal normal bold 60px/65px ${THEME.TEXT.FONT_NAME}`,
  FONT_ALTERNATIVE: `normal normal normal 60px/65px ${THEME.TEXT.FONT_NAME}`,
  LETTER_SPACING: '0px',
  TEXT_TRANSFORM: 'none',
};

THEME.TEXT.H2 = {
  COLOR: THEME.COLORS.PRIMARY,
  COLOR_ALTERNATIVE: THEME.COLORS.BLACK,
  DEFAULT_ALIGN: 'center',
  FONT: `normal normal bold 50px/55px ${THEME.TEXT.FONT_NAME}`,
  FONT_ALTERNATIVE: `normal normal normal 50px/55px ${THEME.TEXT.FONT_NAME}`,
  LETTER_SPACING: '2px',
  TEXT_TRANSFORM: 'none',
};

THEME.TEXT.H3 = {
  COLOR: THEME.COLORS.PRIMARY,
  COLOR_ALTERNATIVE: THEME.COLORS.BLACK,
  DEFAULT_ALIGN: 'left',
  FONT: `normal normal bold 34px/39px ${THEME.TEXT.FONT_NAME}`,
  FONT_ALTERNATIVE: `normal normal normal 34px/39px ${THEME.TEXT.FONT_NAME}`,
  LETTER_SPACING: '0px',
  TEXT_TRANSFORM: 'none',
};

THEME.TEXT.DISPLAY1 = {
  COLOR: THEME.COLORS.SECONDARY,
  DEFAULT_ALIGN: 'left',
  FONT: `normal normal bold 100px/105px ${THEME.TEXT.FONT_NAME}`,
  LETTER_SPACING: '0px',
  TEXT_TRANSFORM: 'none',
};

THEME.TEXT.DISPLAY2 = {
  COLOR: THEME.COLORS.SECONDARY,
  DEFAULT_ALIGN: 'left',
  FONT: `normal normal bold 80px/85px ${THEME.TEXT.FONT_NAME}`,
  LETTER_SPACING: '0px',
  TEXT_TRANSFORM: 'none',
};

THEME.TEXT.DISPLAY3 = {
  COLOR: THEME.COLORS.SECONDARY,
  DEFAULT_ALIGN: 'left',
  FONT: `normal normal bold 40px/50px ${THEME.TEXT.FONT_NAME}`,
  LETTER_SPACING: '0px',
  TEXT_TRANSFORM: 'none',
};

THEME.TEXT.LEAD_TEXT = {
  COLOR: THEME.TEXT.BODY.COLOR,
  DEFAULT_ALIGN: 'left',
  FONT: `normal normal normal 22px/32px ${THEME.TEXT.FONT_NAME}`,
  LETTER_SPACING: '0px',
  TEXT_TRANSFORM: 'none',
};

THEME.TEXT.LEAD_TOPIC = {
  ...THEME.TEXT.LEAD_TEXT,
  TEXT_TRANSFORM: 'uppercase',
};

THEME.TEXT.LEAD_TEXT_HIGHLIGHT = {
  ...THEME.TEXT.LEAD_TEXT,
  FONT: `normal normal bold 22px/32px ${THEME.TEXT.FONT_NAME}`,
};

THEME.TEXT.LEAD_TEXT_HIGHLIGHT2 = {
  ...THEME.TEXT.LEAD_TEXT_HIGHLIGHT,
  COLOR: THEME.COLORS.SECONDARY3,
  TEXT_TRANSFORM: 'uppercase',
};

THEME.TEXT.COMMON_TEXT = {
  COLOR: THEME.TEXT.BODY.COLOR,
  DEFAULT_ALIGN: 'left',
  FONT: `normal normal normal 16px/26px ${THEME.TEXT.FONT_NAME}`,
  LETTER_SPACING: '0px',
  TEXT_TRANSFORM: 'none',
};

THEME.TEXT.COMMON_TOPIC = {
  ...THEME.TEXT.COMMON_TEXT,
  TEXT_TRANSFORM: 'uppercase',
};

THEME.TEXT.COMMON_TEXT_HIGHLIGHT = {
  ...THEME.TEXT.COMMON_TEXT,
  FONT: `normal normal bold 16px/26px ${THEME.TEXT.FONT_NAME}`,
};

THEME.TEXT.COMMON_TEXT_HIGHLIGHT2 = {
  ...THEME.TEXT.COMMON_TEXT_HIGHLIGHT,
  COLOR: THEME.COLORS.SECONDARY3,
  TEXT_TRANSFORM: 'uppercase',
};

THEME.TEXT.LEGAL_TEXT = {
  COLOR: THEME.TEXT.BODY.COLOR,
  DEFAULT_ALIGN: 'left',
  FONT: `normal normal normal 12px/22px ${THEME.TEXT.FONT_NAME}`,
  LETTER_SPACING: '0px',
  TEXT_TRANSFORM: 'none',
};

THEME.TEXT.LEGAL_TOPIC = {
  ...THEME.TEXT.LEGAL_TEXT,
  TEXT_TRANSFORM: 'uppercase',
};

THEME.TEXT.LEGAL_TEXT_HIGHLIGHT = {
  ...THEME.TEXT.LEGAL_TEXT,
  FONT: `normal normal bold 12px/22px ${THEME.TEXT.FONT_NAME}`,
};

THEME.TEXT.LEGAL_TEXT_HIGHLIGHT2 = {
  ...THEME.TEXT.LEGAL_TEXT_HIGHLIGHT,
  COLOR: THEME.COLORS.SECONDARY3,
  TEXT_TRANSFORM: 'uppercase',
};

THEME.TEXT_MOBILE = {
  BODY: {
    ...THEME.TEXT.BODY,
  },
  H1: {
    ...THEME.TEXT.H1,
    FONT: `normal normal bold 50px/55px ${THEME.TEXT.FONT_NAME}`,
    FONT_ALTERNATIVE: `normal normal normal 50px/55px ${THEME.TEXT.FONT_NAME}`,
  },
  H2: {
    ...THEME.TEXT.H2,
    FONT: `normal normal bold 42px/47px ${THEME.TEXT.FONT_NAME}`,
    FONT_ALTERNATIVE: `normal normal normal 42px/47px ${THEME.TEXT.FONT_NAME}`,
  },
  H3: {
    ...THEME.TEXT.H3,
    FONT: `normal normal bold 32px/37px ${THEME.TEXT.FONT_NAME}`,
    FONT_ALTERNATIVE: `normal normal normal 32px/37px ${THEME.TEXT.FONT_NAME}`,
  },
  DISPLAY1: {
    ...THEME.TEXT.DISPLAY1,
    FONT: `normal normal bold 80px/85px ${THEME.TEXT.FONT_NAME}`,
  },
  DISPLAY2: {
    ...THEME.TEXT.DISPLAY2,
    FONT: `normal normal bold 60px/65px ${THEME.TEXT.FONT_NAME}`,
  },
  DISPLAY3: {
    ...THEME.TEXT.DISPLAY3,
    FONT: `normal normal bold 40px/45px ${THEME.TEXT.FONT_NAME}`,
  },
  LEAD_TEXT: {
    ...THEME.TEXT.LEAD_TEXT,
  },
  LEAD_TOPIC: {
    ...THEME.TEXT.LEAD_TOPIC,
  },
  LEAD_TEXT_HIGHLIGHT: {
    ...THEME.TEXT.LEAD_TEXT_HIGHLIGHT,
  },
  LEAD_TEXT_HIGHLIGHT2: {
    ...THEME.TEXT.LEAD_TEXT_HIGHLIGHT2,
  },
  COMMON_TEXT: {
    ...THEME.TEXT.COMMON_TEXT,
  },
  COMMON_TOPIC: {
    ...THEME.TEXT.COMMON_TOPIC,
  },
  COMMON_TEXT_HIGHLIGHT: {
    ...THEME.TEXT.COMMON_TEXT_HIGHLIGHT,
  },
  COMMON_TEXT_HIGHLIGHT2: {
    ...THEME.TEXT.COMMON_TEXT_HIGHLIGHT2,
  },
  LEGAL_TEXT: {
    ...THEME.TEXT.LEGAL_TEXT,
  },
  LEGAL_TOPIC: {
    ...THEME.TEXT.LEGAL_TOPIC,
  },
  LEGAL_TEXT_HIGHLIGHT: {
    ...THEME.TEXT.LEGAL_TEXT_HIGHLIGHT,
  },
  LEGAL_TEXT_HIGHLIGHT2: {
    ...THEME.TEXT.LEGAL_TEXT_HIGHLIGHT2,
  },
};

export default THEME;
